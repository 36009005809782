import constants from "@/constants";
import get from "lodash.get";

export default {
  methods: {
    contestGroupFilterBy(data, path, group) {
      return data.filter((x) => get(x, path) === group);
    },
    contestGroupFilter(data, path) {
      const selectedGroup = this.$root.storage.selectedContestGroup;
      if (selectedGroup === constants.contestGroups.ALL) return data;
      return this.contestGroupFilterBy(data, path, selectedGroup);
    },
  },
};
