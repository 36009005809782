<template>
  <b-col sm="6" md="4" xl="3">
    <b-card
      :class="[
        { 'interactive-card': isInteractive },
        'border-card border-' + borderVariant,
      ]"
      body-class="p-3"
    >
      <b-card-title v-b-popover.hover.top="problem.name" class="text-truncate">
        {{ problem.number }}. {{ problem.name }}
      </b-card-title>

      <b-card-text class="small text-muted mb-2">
        <CountProgress
          :count="problem.submit_counts.feedback"
          :max-count="problem.submit_counts.total"
          label="PDF"
        />
        <CountProgress
          :count="problem.submit_counts.score"
          :max-count="problem.submit_counts.total"
          label="Body"
          class="mb-2"
        />
        <span class="mr-2">
          <CheckOrCross
            :value="problem.submit_counts.final === problem.submit_counts.total"
            :warning="
              0 < problem.submit_counts.final &&
              problem.submit_counts.final < problem.submit_counts.total
            "
            class="mr-1"
          />
          <span class="small">
            {{ problem.submit_counts.final }} /
            {{ problem.submit_counts.total }}
            hodnotení je verejných
          </span>
        </span>
        <div class="small">
          vzorák upravený <span class="text-nowrap">{{ updatedString }}</span>
        </div>
        <CornerIconRibbon
          v-if="isUserEvaluator"
          v-b-popover.hover="'Túto úlohu opravuješ'"
          placement="top-right"
          icon="star"
        />
        <CornerIconJoystick
          v-if="isInteractive"
          v-b-popover.hover="'Toto je interaktívna úloha'"
        />
      </b-card-text>

      <b-card-text class="small text-muted">
        <CategoryBadge
          v-for="category of problem.categories"
          :key="category.name"
          :recommended="category.recommended"
        >
          {{ category.name.substring(0, 1) }}
        </CategoryBadge>
      </b-card-text>

      <div v-b-popover.hover.top="interactiveTitle" class="mb-2">
        <b-button
          block
          class="action-button p-1"
          variant="outline-dark"
          :to="{ name: 'evaluation', params: { problemId: problem.id } }"
          :disabled="!!problem.interactive"
        >
          Opravovanie
        </b-button>
      </div>

      <b-button
        block
        class="action-button p-1"
        variant="outline-dark"
        :to="{ name: 'solution-change', params: { problemId: problem.id } }"
      >
        Vzorák
      </b-button>
    </b-card>
  </b-col>
</template>

<script>
import {
  CategoryBadge,
  CheckOrCross,
  CornerIconRibbon,
  utils,
} from "frontend-common";
import CornerIconJoystick from "@/components/utils/CornerIconJoystick.vue";
import CountProgress from "@/components/utils/CountProgress.vue";

export default {
  name: "ProblemStatus",
  components: {
    CategoryBadge,
    CheckOrCross,
    CountProgress,
    CornerIconRibbon,
    CornerIconJoystick,
  },
  mixins: [],
  props: {
    problem: {
      type: Object,
      required: true,
    },
    isUserEvaluator: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    updatedString() {
      return utils.dateTimeToString(this.problem.updated);
    },
    borderVariant() {
      if (
        this.problem.submit_counts.feedback ===
          this.problem.submit_counts.total &&
        this.problem.submit_counts.score === this.problem.submit_counts.total
      )
        return "success";
      return "warning";
    },
    isInteractive() {
      return !!this.problem.interactive;
    },
    interactiveTitle() {
      return this.isInteractive ? "Toto je interaktívna úloha" : "";
    },
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped>
.interactive-card {
  position: relative;
  background: transparent;
}

.interactive-card::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  inset: 0;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  background-image: url("/icons/tetris.svg");
  background-repeat: no-repeat;
  background-position: bottom left;
  background-size: 100% auto;
}

.action-button:not(:hover) {
  background-color: var(--white);
}

.border-card {
  border-width: 3px;
}
</style>
