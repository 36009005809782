<template>
  <b-img class="icon" src="/icons/joystick-icon.svg" />
</template>

<script>
export default {
  name: "CornerIconJoystick",
};
</script>

<style scoped>
.icon {
  position: absolute;
  top: 0;
  right: -1em;
  object-fit: contain;
  width: 6em;
  transform: rotate(45deg);
}

@keyframes swing {
  20% {
    transform: rotate3d(0, 0, 1, 60deg);
  }

  40% {
    transform: rotate3d(0, 0, 1, 35deg);
  }

  60% {
    transform: rotate3d(0, 0, 1, 50deg);
  }

  80% {
    transform: rotate3d(0, 0, 1, 40deg);
  }

  to {
    transform: rotate3d(0, 0, 1, 45deg);
  }
}

.icon:hover {
  animation-name: swing;
  transform-origin: center center;
  animation-duration: 500ms;
}
</style>
