<template>
  <b-row no-gutters>
    <b-col class="progress-label" cols="auto">
      {{ label }}: {{ count }} / {{ maxCount }}
    </b-col>
    <b-col>
      <b-progress striped class="border">
        <b-progress-bar
          :value="progressValue"
          :max="progressMax"
          :variant="progressVariant"
        />
      </b-progress>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "CountProgress",
  components: {},
  mixins: [],
  props: {
    count: {
      type: Number,
      required: true,
    },
    maxCount: {
      type: Number,
      required: true,
    },
    label: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  computed: {
    progressValue() {
      if (this.maxCount === 0) return 1;
      if (this.count === 0) return this.maxCount / 50;
      return this.count;
    },
    progressMax() {
      return Math.max(1, this.maxCount);
    },
    progressVariant() {
      if (this.count === this.maxCount) return "success";
      if (this.count === 0) return "danger";
      return "warning";
    },
  },
  methods: {},
};
</script>

<style scoped>
.progress-label {
  min-width: 100px;
}
</style>
