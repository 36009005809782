<template>
  <div v-if="round">
    <b-row>
      <b-col>
        <h2 v-b-toggle="`evaluation-overview-round-${round.id}`">
          <font-awesome-icon
            :icon="['fas', expanded ? 'angle-up' : 'angle-down']"
          />
          {{ round.contest.group }}
        </h2>
        <p>
          {{ round.contest.name }} -
          <span class="text-nowrap">{{ round.name }}</span>
        </p>
      </b-col>
      <b-col md="auto">
        <b-button
          :to="{ name: 'evaluators', params: { roundId: round.id } }"
          variant="secondary"
          class="mb-1"
        >
          <font-awesome-icon :icon="['fas', 'user-check']" /> Prihlasovanie
        </b-button>
      </b-col>
    </b-row>
    <b-collapse
      :id="`evaluation-overview-round-${round.id}`"
      v-model="expanded"
    >
      <b-row>
        <b-col>
          <RoundSelectionDropdown
            :contests="contests"
            :rounds="rounds"
            view-name="evaluation-overview"
            show-group
            variant="pmat-blue"
            :url-navigation="false"
            @roundChanged="roundChanged"
          />
          <LoadingSpinner :is-loaded="!isLoading" :fade-on-reload="true">
            <b-row v-if="roundStatus && roundEvaluators">
              <ProblemStatus
                v-for="problem of roundStatus.problems"
                :key="'problem-' + problem.id"
                :problem="problem"
                :is-user-evaluator="isUserEvaluatorOf(problem.id)"
                class="my-2"
              />
            </b-row>
          </LoadingSpinner>
        </b-col>
      </b-row>
    </b-collapse>
  </div>
</template>

<script>
import {
  apiRounds,
  LoadingSpinner,
  RoundSelectionDropdown,
} from "frontend-common";
import ProblemStatus from "./ProblemStatus.vue";

export default {
  name: "ContestGroupEvaluation",
  components: {
    ProblemStatus,
    RoundSelectionDropdown,
    LoadingSpinner,
  },
  mixins: [apiRounds],
  props: {
    contests: {
      type: Array,
      required: true,
    },
    rounds: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      roundStatus: null,
      roundEvaluators: null,
      round: null,
      isLoading: true,
      expanded: false,
    };
  },
  computed: {},
  watch: {
    round: {
      immediate: true,
      handler: function (newValue) {
        if (!newValue) {
          return;
        }
        this.loadStatusAndEvaluators();
      },
    },
  },
  mounted() {
    this.loadRound();
  },
  methods: {
    isUserEvaluatorOf(problemId) {
      return this.roundEvaluators.some(
        (evaluators) =>
          evaluators.problem === problemId &&
          evaluators.user.id === this.$root.state.user.id,
      );
    },
    loadRound() {
      // Get rounds ordered from newest to oldest
      const validRounds = this.rounds
        .filter((round) =>
          this.contests.some((contest) => contest.id === round.contest.id),
        )
        .sort((a, b) => new Date(b.end_time) - new Date(a.end_time));

      // Get the index of the first round that has ended more than two weeks ago
      const firstInvalidIndex = validRounds.findIndex(
        (round) =>
          new Date(round.end_time).getTime() + 2 * 7 * 24 * 60 * 60 * 1000 <
          new Date().getTime(),
      );

      this.round =
        firstInvalidIndex <= 0
          ? validRounds[0]
          : validRounds[firstInvalidIndex - 1];
    },
    async loadStatusAndEvaluators() {
      this.isLoading = true;

      const [status, evaluators] = await Promise.all([
        this.apiRoundStatus(this.round.id),
        this.apiRoundEvaluators(this.round.id),
      ]);

      // Check if user is evaluator of any problem from this round
      const isUserEvaluator = evaluators.some(
        (evaluator) => evaluator.user?.id === this.$root.state.user?.id,
      );

      // If this is the first time we load the round,
      // we need to expland it by default if the user is evaluator
      if (
        this.roundStatus === null &&
        this.roundEvaluators === null &&
        isUserEvaluator
      ) {
        this.expanded = true;
      }

      this.roundStatus = status;
      this.roundEvaluators = evaluators;

      this.isLoading = false;
    },
    roundChanged(round) {
      this.round = round;
    },
  },
};
</script>

<style scoped>
.contest-title {
  margin-top: 2rem;
  font-size: 2rem;
}
</style>
