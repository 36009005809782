<template>
  <b-container class="my-5">
    <b-row>
      <b-col>
        <h1>Opravovanie</h1>
      </b-col>
    </b-row>

    <div v-for="group in groups" :key="group[0].group">
      <hr />
      <ContestGroupEvaluation
        v-if="group && rounds && $root.stateLoaded"
        :contests="group"
        :rounds="rounds"
      />
    </div>
  </b-container>
</template>

<script>
import { apiContests, apiRounds } from "frontend-common";
import ContestGroupEvaluation from "@/components/ContestGroupEvaluation.vue";
import contestGroupFilter from "@/mixins/contestGroupFilter";
import groupBy from "lodash.groupby";

export default {
  name: "EvaluationOverview",
  components: {
    ContestGroupEvaluation,
  },
  mixins: [apiRounds, apiContests, contestGroupFilter],
  props: {},
  data() {
    return {
      rounds: null,
      contests: null,
    };
  },
  computed: {
    groups() {
      return groupBy(this.contests, "group");
    },
  },
  mounted() {
    this.apiContests().then((response) => (this.contests = response.reverse()));

    this.apiRounds().then((response) => (this.rounds = response));
  },
  methods: {},
};
</script>

<style scoped>
.rounds-list-item {
  transition: all 0.2s;
}
.rounds-list-enter,
.rounds-list-leave-to {
  opacity: 0;
  transform: translateX(200px);
}
.rounds-list-leave-active {
  position: absolute;
}
.card {
  border-width: 2px !important;
}
</style>
